<template>
  <label :for="props.id">
    <slot />
  </label>
</template>

<script setup>
// Props
const props = defineProps({
  id: {
    required: true,
    type: String,
  },
})
</script>

<style lang="postcss" scoped>
label {
  @apply text-winter-green-900 text-sm leading-6 uppercase;
}
</style>
